<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <h3 class="font-weight-bold font-18">Invest</h3>
            <hr>
            <div class="mb-3">Available Balance <span class="text-info"> <i class="fa fa-dollar-sign"/><span
                v-if="account">{{ account.investors_account.wallet_total_balance | to2DecimalPlace }}</span><span
                v-else>Loading</span></span>
            </div>
            <form @submit.prevent="withDraw">
              <label for="amount" class="">Amount</label>
              <input type="number" v-model="data.amount" id="amount" placeholder="amount" class="form-control">
              <label for="address" class="mt-3">Address</label>
              <div class="input-group input-group-merge">
                <input type="text" v-model="data.address" id="address" :disabled="enableText"
                       value="0xE988a3c986e78Fce709d9ab8AE65CB8696De68bE" placeholder="Enter your blockchain address"
                       class="form-control">
                <div data-password="false" class="input-group-append" @click="enableTextComponent()">
                  <div class="input-group-text">
                    <span class="password-eye"></span>
                  </div>
                </div>
              </div>
              <button class="btn btn-info mt-4">Submit</button>
            </form>
          </div>
        </div>


      </div>
      <!-- end col -->
    </div>

    <!-- end row -->
    <mobilefooter/>

  </div>
</template>

<script>
import WidgetChart from "/src/views/pages/dashboard/analytics/components/Widget-chart";
import RevenueReport from "/src/views/pages/dashboard/analytics/components/Revenue-report";
import ProductsSales from "/src/views/pages/dashboard/analytics/components/Products-sales";
import MarketingReports from "/src/views/pages/dashboard/analytics/components/Marketing-reports";
import Portlet from "/src/views/pages/dashboard/analytics/components/Portlet";
import RevenueHistory from "/src/views/pages/dashboard/analytics/components/Revenue-history";
import Projections from "/src/views/pages/dashboard/analytics/components/Projections";


export default {
  name: "Withdraw",
  components: {
    mobilefooter: () => import("@/components/MobileFooter")

  },
  data() {
    return {
      loader: false,
      account: '',
      enableText: this.$store.getters.GET_USER_INFO.address,
      data: {
        amount: '',
        address: ''
      }
    }
  },
  methods: {
    enableTextComponent() {
      this.enableText = !this.enableText
    },
    convertTo2dp(value) {
      return value ? (Math.round(value * 100) / 100).toFixed(2) : '';
    },
    getUserAccountInfo() {
      this.accountLoader = true;
      this.$store.dispatch('getUserAccountDetails').then((res) => {
        const {status, extra} = res;
        if (status) {
          this.account = extra;
          this.data.amount = this.convertTo2dp(this.account.investors_account.wallet_total_balance)
        }
      }).finally(() => {
        this.accountLoader = false;
      })
    },
    withDraw() {
      this.$store.dispatch('withdraw', this.data).then((res) => {
        this.data.address = '';
        this.data.amount = '';
      })
    },
  },
  created() {
    this.getUserAccountInfo();
  }

}
</script>

<style scoped>

</style>